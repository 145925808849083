// Funkce pro kontrolu scrollování a aktualizaci tříd
function checkScroll() {
  const menu = document.getElementById('menu');
  if (menu.classList.contains('menu-transparent') && window.scrollY > 50) {
    menu.classList.add('bg-white', 'text-black', 'border-b-[1px]', 'border-solid', 'border-gray-200');
    menu.classList.remove('text-white');
  } 
  else if (!menu.classList.contains('menu-transparent') && window.scrollY > 50) {
    menu.classList.add('border-b-[1px]', 'border-solid', 'border-gray-200', 'bg-white', '!text-black',);
  } else {
    menu.classList.remove('bg-white', 'text-black', 'border-b-[1px]', 'border-solid', 'border-gray-200');
    menu.classList.add('text-white');
  }
}

// Event listener pro scrollování
window.addEventListener('scroll', checkScroll);

// Získání ovládacích prvků a úprava jejich tříd
const btnService = document.getElementById('btn-service');
const menuService = document.getElementById('menu-service');
const menuOverlay = document.getElementById('menu-service__overlay');
const svgElement = btnService.querySelector('svg');

// Funkce pro přepnutí tříd
function toggleMenuClasses() {
  menuService.classList.toggle('hidden');
  menuOverlay.classList.toggle('hidden');
  menuService.classList.toggle('fixed');
  menuOverlay.classList.toggle('fixed');
  svgElement.classList.toggle('text-primary');
}

btnService.addEventListener('click', toggleMenuClasses);

menuService.addEventListener('mouseleave', () => {
  menuService.classList.add('hidden');
  menuService.classList.remove('fixed');
  menuOverlay.classList.add('hidden');
  menuOverlay.classList.remove('fixed');
  svgElement.classList.remove('text-primary');
});

// Přidání event listeneru na prvek s ID 'menuButton'
document.getElementById('menuButton').addEventListener('click', function() {
  const menuContents = document.querySelectorAll('.menu-content');
  const menu = document.getElementById('menu');

  // Přepnutí tříd pro všechny prvky s třídou 'menu-content'
  menuContents.forEach(menuContent => {
    menuContent.classList.toggle('hidden');
  });

  // Další části kódu zůstávají nezměněné
  menu.classList.toggle('bottom-0');
  menu.classList.toggle('flex');
  menu.classList.toggle('w-full');
  menu.classList.toggle('flex-col');
  menu.classList.toggle('!bg-white');
  menu.classList.toggle('visible');
  menu.classList.toggle('opacity-100');
  menu.classList.toggle('text-black');
  menu.classList.toggle('!fixed');

   // Toggle classes for menu icons transformations
   const menu1 = document.getElementById('menu_1');
   const menu2 = document.getElementById('menu_2');
   const menu3 = document.getElementById('menu_3');
 
   menu1.classList.toggle('origin-top-left');
   menu1.classList.toggle('rotate-45');
   menu1.classList.toggle('-translate-y-0.5');
   menu1.classList.toggle('translate-x-1');
   
   menu2.classList.toggle('opacity-0');
   
   menu3.classList.toggle('origin-bottom-left');
   menu3.classList.toggle('rotate-[-45deg]');
   menu3.classList.toggle('translate-y-0.5');
   menu3.classList.toggle('translate-x-1');
 
  // Přepnutí tříd overflow-hidden a h-screen pro element s ID 'body'
  const bodyElement = document.getElementById('body');
  bodyElement.classList.toggle('overflow-hidden');
  bodyElement.classList.toggle('h-screen');
});

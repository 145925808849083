import $ from 'jquery'

// Cache selectors
const $slider = $('#slider')
const $arrowLeft = $('.arrow--left')
const $arrowRight = $('.arrow--right')

$slider.slick({
  infinite: true,
  variableWidth: true,
  speed: 300,

  responsive: [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 3,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 1800,
        prevArrow: $arrowLeft,
        nextArrow: $arrowRight,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        dotsClass: 'flex items-center justify-center slicker-main',
      },
    },
  ],
})

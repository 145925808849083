import $ from 'jquery'

const toggleModal = (buttonSelector, modalSelector, contentSelector) => {
  $(buttonSelector).click(function () {
    $('body').toggleClass('overflow-hidden w-full h-full');
    $(modalSelector).toggleClass('hidden');

    // Hide all video contents first
    $('[id^="video-content"]').hide();

    // Show the specific video content associated with contentSelector
    $(contentSelector).show();
  });
};

// Mapování tlačítek na příslušné modální okno a obsah
const mapping = {
  '#video-1': { modal: '#modal', content: '#video-content-1' },
  '#video-2': { modal: '#modal', content: '#video-content-2' },
  '#video-3': { modal: '#modal', content: '#video-content-3' },
  // Přidej další mapování podle potřeby
};

// Projdeme mapování a připojíme klikací události
for (let button in mapping) {
  const { modal, content } = mapping[button];
  toggleModal(button, modal, content);
}

// Funkce pro zavření modálního okna
$('#modal-close').click(function() {
  $('body').removeClass('overflow-hidden w-full h-full');
  $('#modal').addClass('hidden');
});
